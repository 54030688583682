import * as React from "react";

import { PageLayout, SEO } from "@components/page-layout";
import { ProvincesSection } from "@containers/provinces";

const ProvincesPage = () => (
  <PageLayout>
    <SEO title="Provinces" />
    <ProvincesSection />
  </PageLayout>
);

export default ProvincesPage;
